import React from 'react';

const Languages = ({ spoken_languages }) => {
  // Helper function to safely get the value or return an empty string if undefined
  const safeGet = (value) => (value ? value : '');

  return (
    <div className="languagessnew">

      {spoken_languages && spoken_languages.length > 0 ? (
        spoken_languages.map((linguas, index) => (
          <div key={index} className="languages-entry">
            <p className="profile-description language-description">
              {[
                safeGet(linguas.language),
                safeGet(linguas.proficiency),
              ]
                .filter(Boolean) // Filters out any empty strings or undefined values
                .join(' - ')}
            </p>
            {/* <div className="experience-bar"></div> */}
          </div>
        ))
      ) : (
      <p>No languages available.</p>
      )}
      <div className="experience-bar"></div>
    </div>
  );
};

export default Languages;
