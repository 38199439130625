import React from 'react';

const Education = ({ academic_timeline }) => {
  // Helper function to safely get the value or return an empty string if undefined
  const safeGet = (value) => (value ? value : '');

  return (
    <div className="educationnew">
      {academic_timeline && academic_timeline.length > 0 ? (
        academic_timeline.map((educacao, index) => (
          <div key={index} className="education-entry">
            <p className="profile-description">
              {/* Extract institution separately for styling */}
              <span style={{ color: '#1496D2', fontWeight: 'bold' }}>
                {safeGet(educacao.institution)}
              </span>{' '}
              -{' '}
              {[
                safeGet(educacao.degree),
                safeGet(educacao.course),
                safeGet(educacao.academic_field),
                `(${safeGet(educacao.country)} ${safeGet(educacao.year)})`,
                safeGet(educacao.city),
                safeGet(educacao.country),
              ]
                .filter(Boolean) // Filters out any empty strings or undefined values
                .join(' - ')}
            </p>
            <div className="experience-bar"></div>
          </div>
        ))
      ) : (
        <p>No academic history available.</p>
      )}
    </div>

  );
};

export default Education;
