import React from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Row, Col } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const StatisticsPage = () => {
  const eventData = [
    {
      title: 'International MBA Statistics',
      demographics: [
        { value: '55', labelOne: 'Students', labelTwo: '38% Women' },
        { value: '80%', labelOne: 'International', labelTwo: '12 Nationalities' },
        { value: '28', labelOne: 'Years', labelTwo: 'Average Age' },
        { value: '6', labelOne: 'Years', labelTwo: 'Average Years of Experience' },
      ],
      academicBackgroundData: {
        labels: ['Engineering', 'Business', 'Science', 'Social Sciences'],
        datasets: [{
          data: [49, 29, 12, 10],
          backgroundColor: ['#0D3D63', '#009FE3', '#FC3E2C', '#81C8BC'],
          hoverBackgroundColor: ['#0a2f4c', '#008dca', '#fc2713', '#6fc0b3'],
        }],
      },
      sectorsData: {
        labels: ['Energy', 'Consultancy', 'Transport', 'Industrial', 'Consumer Goods'],
        datasets: [{
          label: 'Number of Students',
          data: [15, 12, 10, 8, 8],
          backgroundColor: '#0D3D63',
          borderColor: '#0D3D63',
          borderWidth: 1,
        }],
      },
    },
    {
      title: 'Executive MBA Statistics',
      demographics: [
        { value: '55', labelOne: 'Students', labelTwo: '38% Women' },
        { value: '80%', labelOne: 'International', labelTwo: '12 Nationalities' },
        { value: '28', labelOne: 'Years', labelTwo: 'Average Age' },
        { value: '6', labelOne: 'Years', labelTwo: 'Average Years of Experience' },
      ],
      academicBackgroundData: {
        labels: ['Law', 'Medicine', 'Engineering', 'Arts'],
        datasets: [{
          data: [30, 25, 25, 20],
          backgroundColor: ['#0D3D63', '#009FE3', '#FC3E2C', '#81C8BC'],
          hoverBackgroundColor: ['#0a2f4c', '#008dca', '#fc2713', '#6fc0b3'],
        }],
      },
      sectorsData: {
        labels: ['Technology', 'Healthcare', 'Finance', 'Education', 'Government'],
        datasets: [{
          label: 'Number of Participants',
          data: [20, 18, 15, 10, 8],
          backgroundColor: '#009FE3',
          borderColor: '#009FE3',
          borderWidth: 1,
        }],
      },
    },
  ];

  return (
    <Row className="p-4" style={{ fontFamily: 'Roboto, sans-serif' }}>
      {eventData.map((event, index) => (
        <Col
          key={index}
          xs={12} sm={12} md={12} lg={12} xl={6}
          className={index === 0 ? 'border-end' : 'border-start'}
          style={{ borderColor: '#0055a5', padding: '0 1rem' }}
        >
          <h2 className="text-center mt-4 mb-4" style={{ color: '#0055a5', fontSize: '20px' }}>
            {event.title}
          </h2>

          {/* Event Demographics */}
          <Row className="mb-4">
            {event.demographics.map((stat, index) => (
              <Col key={index} xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}
                   className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                <div className="d-flex justify-content-center align-items-center mb-3"
                     style={{ width: '80px', height: '80px', borderRadius: '50%', backgroundColor: event.title === 'International MBA Statistics' ? '#0D3D63' : '#009FE3' }}>
                  <h3 className="text-white m-0" style={{ fontSize: '24px' }}>{stat.value}</h3>
                </div>
                <p className="m-0" style={{ fontSize: '14px', color: '#0D3D63', fontWeight: 600 }}>
                  {stat.labelOne}
                </p>
                <p className="mb-3" style={{ fontSize: '11px', color: '#0D3D63' }}>
                  {stat.labelTwo}
                </p>
              </Col>
            ))}
          </Row>

          {/* Event Charts */}
          <h3 className="text-center mt-5 mb-4" style={{ color: '#0055a5', fontSize: '20px' }}>
            Academic Background / Main Sectors
          </h3>
          <Row className="justify-content-around">
            <Col className="mb-4" xs={8} sm={8} md={5} lg={5} xl={6}>
              <Pie data={event.academicBackgroundData} options={{ responsive: false, plugins: { legend: { position: 'right' } } }} />
            </Col>
            <Col className="mb-4" xs={8} sm={8} md={5} lg={5} xl={6}>
              <Bar data={event.sectorsData} options={{ indexAxis: 'y', responsive: true, plugins: { legend: { display: false } } }} />
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default StatisticsPage;
