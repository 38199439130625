import React from 'react';

const Experience = ({ work_timeline, professional_experience }) => {
  return (
    <div className="experiencenew">
      {work_timeline && work_timeline.length > 0 ? (
        work_timeline.map((work, index) => (
          <div key={index} className="experience-item">
            <div className="experience-header">
              <h2>{work.company_name} — {work.position}</h2>
              <span className="date-range">
                {work.start} - {work.end ? work.end : "Present"}
              </span>
            </div>
            <p className="profile-description">{work.description}</p>
            <div className="experience-bar"></div>
          </div>
        ))
      ) : (
        <p>No work experience available.</p>
      )}
    </div>
  );
};

export default Experience;
