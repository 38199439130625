import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faPhone, faEnvelope, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Accordion, Card, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { Colors } from 'chart.js';

const Bio = ({ name, email1, email2, phone1, phone2, nationality, linkedin, program, photo, bio, bio_title }) => {
  const BASE_PATH = process.env.REACT_APP_PATH_API_BACKEND
  const DEFAULT_PICTURE = process.env.REACT_APP_DEFAULT_PICTURE
  const photoUrl = photo ? `${BASE_PATH}${photo}` : `${DEFAULT_PICTURE}`;
  return (
    <Row className='sidebarnew remove-row-margin'>
      <Col xs={12} sm={6} md={6} lg={2} xl={2} className="mt-4 mx-12">
        <div className='backButton'>
          <a style={{color: "#FFFFFF"}} href="#" onClick={() => window.history.back()}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </a>
        </div>
        
        <div className="avatarcontainer">
          <div className="semicircle-left"></div>
          <div className="avatarnew">
            <img src={photoUrl} alt={name} />
          </div>
        </div>
      </Col>
      
      <Col xs={12} sm={6} md={6} lg={5} xl={3} className="mt-4">
        
      <div>
          <h2>{name}</h2>
        </div>
        <div className="socialnew">
          {nationality && (
            <p>
              <FontAwesomeIcon icon={faGlobe} /> &nbsp;
              <span>{nationality}</span>
            </p>
          )}
        </div>

        <div className="socialnew">
          {phone1 && (
            <p>
              <FontAwesomeIcon icon={faPhone} /> &nbsp;
              <a style={{ color: "#FFFFFF", cursor: "pointer" }} href={`tel:${phone1}`}>{phone1}</a>
              {phone2 && (
                <>
                  &nbsp;/&nbsp;
                  <a style={{ color: "#FFFFFF", cursor: "pointer" }} href={`tel:${phone2}`}>{phone2}</a>
                </>
              )}
            </p>
          )}

          {email1 && (
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
              <a style={{ color: "#FFFFFF", cursor: "pointer" }} href={`mailto:${email1}`}>{email1}</a>
              {email2 && (
                <>
                  &nbsp;/<br/>
                  <a style={{ color: "#FFFFFF", cursor: "pointer" }} href={`mailto:${email2}`}>{email2}</a>
                </>
              )}
            </p>
          )}

          {linkedin && (
            <p>
              <FontAwesomeIcon icon={faLinkedin} /> &nbsp;
              <a style={{ color: "#FFFFFF", cursor: "pointer" }} href={`https://www.linkedin.com/in/${linkedin}`} target="_blank" rel="noopener noreferrer">{linkedin}</a>
            </p>
          )}
        </div>

      </Col>

      <Col xs={12} sm={12} md={12} lg={5} xl={7} className="mt-4 pe-5">
        <div style={{textAlign: 'justify'}}>
          <p>{bio ? bio : "I love the Lisbon MBA!"}</p>
        </div>
      </Col>
    </Row>
  );
};

export default Bio;
