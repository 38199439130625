import React from 'react';
import { Link } from 'react-router-dom';
import '../StudentsPage/students/styles.css'; // Import CSS file for styling

const base_path = process.env.REACT_APP_PATH_API_BACKEND

const TopMenu = () => {
  return (
    <div className="top-menu"> {/* Apply CSS class for styling */}
      <div className="links">
        <Link to={base_path} className="link">Middle Office</Link>
        <Link to="/" className="link">Home</Link>
        <Link to="/about" className="link">About Us</Link>
        <Link to="/class-profile" className="link">Class Profile</Link>
      </div>
    </div>
  );
};

export default TopMenu;
