import React, { useState, useEffect } from 'react';
import StudentsPage from '../StudentsPage/StudentsPage';
import StatisticsPage from './StatisticsPage.js';
import mba_button from '../../images/btn_MBA.png';
import { Container, Row, Col } from 'react-bootstrap';

const class_year = process.env.REACT_APP_CLASS_YEAR;

const SecondaryContent = () => {
  const [showStudentsPage, setShowStudentsPage] = useState(false);
  const [showStatistics, setShowStatistics] = useState(true); 
  const [selectedDiv, setSelectedDiv] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(''); 

  useEffect(() => {
    const storedProgram = localStorage.getItem('MBA');
    if (storedProgram) {
      setSelectedProgram(storedProgram);
      setSelectedDiv(storedProgram === 'International MBA' ? 1 : 2);
      setShowStudentsPage(true);
      setShowStatistics(false);
    }
  }, []);

  const handleDivClick = (divId, program) => {
    if (selectedDiv === divId) {
      setShowStudentsPage(prevState => !prevState);
      setShowStatistics(prevState => !prevState);
      setSelectedDiv(null);
      setSelectedProgram('');
      localStorage.removeItem('MBA');
    } else {
      setShowStudentsPage(true);
      setShowStatistics(false);
      setSelectedDiv(divId);
      setSelectedProgram(program);
      localStorage.setItem('MBA', program);
    }
  };

  return (
    <div style={styles.secondaryContent}>
      <div style={styles.contentBeforeStudents}>
        <h1 style={styles.mbasTitle}>Talent Book</h1>
        <div style={styles.descriptionText}>
          <p>
          Welcome to The Lisbon MBA Talent Book, a unique pool of talented professionals ready to make a positive impact in business and society. The Lisbon MBA, a joint venture between CATÓLICA-LISBON and Nova SBE, in collaboration with MIT Sloan, aims to develop global principled leaders capable of contributing to serve organizations with purpose. 
The Lisbon MBA programs extend beyond business fundamentals, integrating up-to-date management trends and a unique framework for leadership development. Our students are challenged to embrace a transformational journey, fostering strategic, critical thinking with an innovative mindset to tackle complex business challenges individually and in high-performing teams.
The Career Management Center at The Lisbon MBA is ready to collaborate with your organization to enhance its talent. For more information on how to leverage our services, contact us at <a href="mailto:careermanagement@thelisbonmba.com">careermanagement@thelisbonmba.com</a>.
          </p>
        </div>
        <Container>

        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <div
              style={{
                ...styles.bootstrapClickableDiv,
                backgroundImage: `url(${mba_button})`,
                opacity: selectedDiv === 2 ? 0.5 : 1,
                color: 'white', // Cor do texto
              }}
              onClick={() => handleDivClick(1, 'International MBA')}
            >
              <h1 style={{ position: 'absolute'}}>
                International MBA
              </h1>
              <h3 style={{ position: 'relative', top: '30%'}}>
                {`${class_year} / ${parseInt(class_year) + 2}`}
              </h3>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <div
              style={{
                ...styles.bootstrapClickableDiv,
                backgroundImage: `url(${mba_button})`,
                opacity: selectedDiv === 1 ? 0.5 : 1,
                color: 'white', // Cor do texto
              }}
              onClick={() => handleDivClick(2, 'Executive MBA')}
            >
              <h1 style={{ position: 'absolute'}}>
                Executive MBA
              </h1>
              <h3 style={{ position: 'relative', top: '30%'}}>
                {`${class_year} / ${parseInt(class_year) + 2}`}
              </h3>
            </div>
          </Col>
        </Row>

        </Container>
      </div>
      {showStudentsPage && <StudentsPage selectedProgram={selectedProgram} />}
      {showStatistics && <StatisticsPage />}
    </div>
  );
};

const styles = {
  secondaryContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentBeforeStudents: {
    backgroundColor: '#0D3D63',
    width: "100%",
  },
  clickableDivsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    padding: "20px", 
  },
  clickableDiv: {
    width: '50%',
    height: '200px',
    border: '1px solid transparent',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: "10px"
  },
  bootstrapClickableDiv: {
    height: '200px',
    border: '1px solid transparent',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: "10px"
  },
  mbasTitle: {
    textAlign: 'center',
    marginBottom: 40,
    marginTop: 20,
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 70,
  },
  descriptionText: {
    textAlign: 'justify',
    margin: 50,
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 20,
    padding: 20,
  }
};

export default SecondaryContent;
