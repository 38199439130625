import React from 'react';
import banner from '../../images/banner-homepage-v2-2020.jpg';
import ftLogo from '../../images/ft-logo.png'; // Add your FT logo image path here

const MainContent = () => {
  return (
    <div style={styles.mainContent}>
      <div style={styles.textContainer}>
        <h1 style={styles.heading}>Where Leaders are Shaped</h1>
        <p style={styles.subheading}>Among the best MBAs in the world since 2013.</p>
        <p style={styles.ranking}>
          <span style={styles.highlight}>#24 International MBA</span> &amp; <span style={styles.highlight}>#36 Executive MBA in Europe</span>
        </p>
        <p style={styles.globalRanking}>
          <span style={styles.globalHighlight}>#4 in the World in International Course Experience</span>
        </p>
        <div style={styles.ftContainer}>
          <img src={ftLogo} alt="FT Global MBA 2024 Ranking" style={styles.ftLogo} />
          <span>GLOBAL MBA 2024 RANKING</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainContent: {
    display: 'flex',
    justifyContent: 'flex-start', // Align content to the left
    alignItems: 'center',
    // padding: '20px',
    backgroundImage: `url(${banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '340px',
    color: '#003366', // Blue color for the text
  },
  textContainer: {
    // padding: '20px',
    maxWidth: '600px', // Limit the width to prevent text from stretching too much
    marginLeft: '55px', // Add a margin of 20px to the left
  },
  
  heading: {
    fontSize: '2.5em',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  subheading: {
    fontSize: '1.2em',
    marginBottom: '20px',
  },
  ranking: {
    fontSize: '1.2em',
    marginBottom: '10px',
  },
  highlight: {
    color: '#d32f2f',
    fontWeight: 'bold',
  },
  globalRanking: {
    fontSize: '1.2em',
    marginBottom: '20px',
  },
  globalHighlight: {
    color: '#d32f2f',
    fontWeight: 'bold',
  },
  ftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  ftLogo: {
    width: '40px',
    height: 'auto',
    marginRight: '10px',
  },
};

export default MainContent;
