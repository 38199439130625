import React from 'react';
import TopMenu from './TopMenu';
import MainContent from './MainContent';
import SecondaryContent from './SecondaryContent';
import Footer from './Footer';
import HeaderBar from './HeaderBar';

const HomePage = () => {
  return (
    <div style={styles.pageContainer}>
      {/* <HeaderBar /> */}
      {/* <TopMenu /> */}
      <div style={styles.contentWrapper}>
        {/* <MainContent /> */}
        <SecondaryContent />
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#FFFFFF',
    width: "100%",
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }
};

export default HomePage;
