import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useParams } from 'react-router-dom';
import StudentProfileNew from './students/StudentProfileNew';
import { Accordion, Card, Button, Form, Container, Row, Col } from 'react-bootstrap';
import './StudentsPage.css';

const class_year = process.env.REACT_APP_CLASS_YEAR;

const class_path = process.env.REACT_APP_PATH_API_BACKEND_CLASS

const base_path = process.env.REACT_APP_PATH_API_BACKEND

const DEFAULT_PICTURE = process.env.REACT_APP_DEFAULT_PICTURE


const fetchStudentsData = async () => {
  try {
    const response = await fetch(`${class_path}/${class_year}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.students; 

  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

const fetchFilterData = async (selectedProgram) => {
  try {
    const response = await fetch(`${class_path}/${class_year}/${selectedProgram}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.filters; 

  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

const TabContent = ({ students }) => {
  const BASE_PATH = base_path;

  return (
    <ul className="listalunos">
      <Row>
        {students.length > 0 ? (
          students.map(student => (
            <Col xs={6} sm={4} md={3} lg={3} xl={3} xxl={2} key={student.public_id} className="mt-4 mb-4">
              <li>
                <Link to={`/students/${student.public_id}`}>
                  <div className="student_item">
                    <div
                      className="student_image"
                      style={{
                        backgroundImage: `url('${student.photo ? BASE_PATH + student.photo : DEFAULT_PICTURE}')`,
                      }}
                    ></div>
                    <div className="student_name text-center">
                      <span>{student.name}</span>
                    </div>
                  </div>
                </Link>
              </li>
            </Col>
          ))
        ) : (
          <Col xs={12}>
            <div className="no-students-message text-center">
              <p>No students found matching the selected filters.</p>
            </div>
          </Col>
        )}
      </Row>
    </ul>
  );
};
     
const Filters = ({ filters, onFilterChange, onClearAll, selectedFilters }) => {
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const toggleCategory = (category) => {
    setOpenCategory(prevCategory => (prevCategory === category ? null : category));
  };

  const formatCategory = (category) => {
    return category.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };

  const handleLanguageChange = (language, checked) => {
    onFilterChange('spoken_languages', language, checked);

    if (!checked) {
        // Se a língua for desmarcada, remove as proficiências associadas
        const associatedProficiencies = selectedFilters['proficiency']?.[language] || [];
        
        // Verifique se associatedProficiencies é um array antes de usar forEach
        if (Array.isArray(associatedProficiencies)) {
            associatedProficiencies.forEach(proficiency => {
                onFilterChange('proficiency', { language, proficiency }, false);
            });
        } else {
            console.warn(`associatedProficiencies não é um array: ${associatedProficiencies}`);
        }
        
        // Resetar a seleção da língua se for desmarcada
        if (selectedLanguage === language) {
            setSelectedLanguage(null); // Resetar o selectedLanguage
        }
    } else {
        setSelectedLanguage(language);
    }
  };


  const handleProficiencyChange = (proficiency, checked) => {
    if (selectedLanguage) {
        onFilterChange('proficiency', { language: selectedLanguage, proficiency }, checked);
    }
  };

  // useEffect para limpar proficiências não associadas
  useEffect(() => {
    if (selectedFilters['spoken_languages']) {
        const spokenLanguages = selectedFilters['spoken_languages'];
        const proficienciesToRemove = Object.keys(selectedFilters['proficiency'] || {}).filter(language => !spokenLanguages.includes(language));

        proficienciesToRemove.forEach(language => {
            const associatedProficiencies = selectedFilters['proficiency']?.[language] || [];
            
            // Verifique se associatedProficiencies é um array antes de usar forEach
            if (Array.isArray(associatedProficiencies)) {
                associatedProficiencies.forEach(proficiency => {
                    onFilterChange('proficiency', { language, proficiency }, false);
                });
            } else {
                console.warn(`associatedProficiencies não é um array: ${associatedProficiencies}`);
            }
        });
    }
  }, [selectedFilters['spoken_languages']]);


  return (
    <div className="filters-container">
      <div className="filters-header">
        <h3>Filters</h3>
      </div>
      <div className="d-flex justify-content-end">
        <Button 
          variant="link" 
          onClick={onClearAll}
          style={{ fontWeight: 'bold', color: 'black' }}
        >
          Clear all
        </Button>
      </div>

      <Accordion>
        {filters && Object.entries(filters).map(([category, options], index) => {
          if (category === 'proficiency' || category === 'spoken_languages') return null;
          
          return (
            <Card key={category} className="filter-category m-0">
              <Card.Header>
                <Accordion.Button 
                  as={Button} 
                  variant="link" 
                  onClick={() => toggleCategory(category)}
                  style={{ textAlign: 'left', background: 'none', color: 'black', justifyContent: 'flex-start', padding: '0.5rem 1rem', textDecoration: 'none', fontWeight: 'bold' }}
                >
                  {openCategory === category ? '▾' : '▸'} {formatCategory(category)}
                </Accordion.Button>
              </Card.Header>
              <Accordion.Collapse eventKey={index.toString()} in={openCategory === category}>
                <Card.Body>
                  <ul>
                    {options.map(option => (
                      <li key={option}>
                        <Form.Check 
                          type="checkbox" 
                          id={`${category}-${option}`} 
                          label={option} 
                          className="filter-checkbox-label"
                          checked={selectedFilters[category] && selectedFilters[category].includes(option)}
                          onChange={(e) => onFilterChange(category, option, e.target.checked)}
                        />
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}

        {filters.spoken_languages && (
          <Card key="spoken_languages" className="filter-category m-0">
            <Card.Header>
              <Accordion.Button 
                as={Button} 
                variant="link" 
                onClick={() => toggleCategory('spoken_languages')}
                style={{ textAlign: 'left', background: 'none', color: 'black', justifyContent: 'flex-start', padding: '0.5rem 1rem', textDecoration: 'none', fontWeight: 'bold' }}
              >
                {openCategory === 'spoken_languages' ? '▾' : '▸'} Spoken Languages
              </Accordion.Button>
            </Card.Header>
            <Accordion.Collapse eventKey="spoken_languages" in={openCategory === 'spoken_languages'}>
              <Card.Body>
                <ul>
                  {filters.spoken_languages.map(language => (
                    <li key={language}>
                      <Form.Check 
                        type="checkbox" 
                        id={`spoken_languages-${language}`} 
                        label={language} 
                        className="filter-checkbox-label"
                        checked={selectedFilters['spoken_languages'] && selectedFilters['spoken_languages'].includes(language)}
                        onChange={(e) => handleLanguageChange(language, e.target.checked)}
                      />
                    </li>
                  ))}
                </ul>
                {selectedFilters['spoken_languages']?.length > 0 && (
                  <div>
                    <h6 style={{ textAlign: 'left', marginLeft: '1rem' }}>Proficiency</h6>
                    <ul>
                      {filters.proficiency.map(proficiency => (
                        <li key={proficiency}>
                          <Form.Check 
                            type="checkbox" 
                            id={`proficiency-${proficiency}`} 
                            label={proficiency} 
                            className="filter-checkbox-label"
                            checked={selectedFilters['proficiency']?.[selectedLanguage]?.includes(proficiency) || false}
                            onChange={(e) => handleProficiencyChange(proficiency, e.target.checked)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    </div>
  );
};


const Tabs = ({ selectedProgram }) => {
  const [studentData, setStudentData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    const storedFilters = localStorage.getItem('Filters');
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      if (parsedFilters[selectedProgram]) {
        setSelectedFilters(parsedFilters[selectedProgram]);
      } else {
        setSelectedFilters({});
      }
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      const storedFilters = localStorage.getItem('Filters');
      const parsedFilters = storedFilters ? JSON.parse(storedFilters) : {};
      parsedFilters[selectedProgram] = selectedFilters;
      localStorage.setItem('Filters', JSON.stringify(parsedFilters));
    }
  }, [selectedFilters, selectedProgram]);

  useEffect(() => {
    const loadStudentData = async () => {
      const dataStudent = await fetchStudentsData();
      const dataFilter = await fetchFilterData(selectedProgram);
      if (dataStudent && dataFilter) {
        const response_studentData = dataStudent.filter(student => student.program === selectedProgram); 
        setStudentData(response_studentData);
        setFilterData(dataFilter);
      } else {
        setError('Failed to load data');
      }
      setLoading(false);
    };

    loadStudentData();
  }, [selectedProgram]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const handleFilterChange = (category, option, isChecked) => {
    setSelectedFilters(prevFilters => {
        const updatedFilters = { ...prevFilters };

        if (category === 'proficiency' && option) {
            const { language, proficiency } = option;

            if (!updatedFilters[category]) {
                updatedFilters[category] = {};
            }
            if (!updatedFilters[category][language]) {
                updatedFilters[category][language] = [];
            }

            if (isChecked) {
                updatedFilters[category][language].push(proficiency);
            } else {
                updatedFilters[category][language] = updatedFilters[category][language].filter(opt => opt !== proficiency);

                if (updatedFilters[category][language].length === 0) {
                    delete updatedFilters[category][language];
                }
            }

            if (Object.keys(updatedFilters[category]).length === 0) {
                delete updatedFilters[category];
            }
        } else if (category !== 'proficiency') {
            if (!updatedFilters[category]) {
                updatedFilters[category] = [];
            }
            if (isChecked) {
                updatedFilters[category].push(option);
            } else {
                updatedFilters[category] = updatedFilters[category].filter(opt => opt !== option);
            }
        }

        return updatedFilters;
    });
};


  const handleClearAll = () => {
    setSelectedFilters({});
    const storedFilters = localStorage.getItem('Filters');
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      delete parsedFilters[selectedProgram];
      localStorage.setItem('Filters', JSON.stringify(parsedFilters));
    }
  };

  const filteredStudents = studentData?.filter(student => {
    return Object.entries(selectedFilters).every(([category, options]) => {
      if (!options || options.length === 0) return true;
  
      if (category === 'spoken_languages') {
        return student.spoken_languages.some(lang => options.includes(lang.language));
      }
  
      if (category === 'proficiency') {
        return student.spoken_languages.some(lang => {
          const proficiencies = selectedFilters['proficiency']?.[lang.language];
          return proficiencies && proficiencies.includes(lang.proficiency);
        });
      }
  
      const studentField = student[category];
      if (Array.isArray(studentField)) {
        return options.every(opt => studentField.includes(opt));
      }
      return options.includes(studentField);
    });
  }) || [];
  

  return (
    <Container fluid className="tabs">
      <Row>
        <Col xs={12} lg={9}>
          <div className="tabs-students">
            {studentData && <TabContent students={filteredStudents} />}
          </div>
        </Col>
        <Col xs={12} lg={3} className="tabs-filters">
          <Filters 
            filters={filterData} 
            onFilterChange={handleFilterChange} 
            onClearAll={handleClearAll}
            selectedFilters={selectedFilters} 
          />
        </Col>
      </Row>
    </Container>
  );
};

const StudentProfileWrapper = () => {
  let { studentId } = useParams();
  const [studentInfo, setStudentInfo] = useState(null);
  useEffect(() => {
    const fetchStudentInfo = async () => {
      const data = await fetchStudentsData();
      const student = data.find(student => student.public_id === studentId);
      setStudentInfo(student);
    };

    fetchStudentInfo();
  }, [studentId]);

  if (!studentInfo) return <div>Student not found</div>;

  return <StudentProfileNew student={studentInfo} />;
};

const StudentsPage = ({ selectedProgram }) => {
  const [selectedFilters, setSelectedFilters] = useState({});

  // useEffect para limpar os filtros ao montar a página
  useEffect(() => {
    setSelectedFilters({});
    localStorage.removeItem('Filters'); // Opcional: remove os filtros do localStorage
  }, [selectedProgram]); // Executa quando selectedProgram muda

  return (
      <Routes>
        <Route path="/" element={<Tabs selectedProgram={selectedProgram} selectedFilters={selectedFilters} />} />
        <Route path=":studentId" element={<StudentProfileWrapper />} />
      </Routes>
  );
};


export default StudentsPage;