import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TopMenu from '../../HomePage/TopMenu';
import HeaderBar from '../../HomePage/HeaderBar';
import Footer from '../../HomePage/Footer.js';
import Bio from './BioNew';
import Introduction from './IntroductionNew';
import Education from './EducationNew';
import Experience from './ExperienceNew';
import AdditionalInformation from './AdditionalInformationNew.js';
import Languages from './LanguagesNew.js';
import { Accordion, Card, Button, Form, Container, Row, Col } from 'react-bootstrap';

import './styles.css';

const STUDENT_DETAILS = process.env.REACT_APP_STUDENT_DETAILS

const StudentProfileNew = ({ student }) => {
  const [studentData, setStudentData] = useState(null);

  const fetchStudentsData = async (studentId) => {
    try {
      const url = `${STUDENT_DETAILS}/${studentId}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      return data.students;
    } catch (error) {
      console.error('Error fetching student data:', error);
      return null;
    }
  };

  useEffect(() => {
    const handleStudentData = async () => {
      const studentId = student.public_id;
      const fetchedStudentData = await fetchStudentsData(studentId);
      setStudentData(fetchedStudentData[0]);
    };

    handleStudentData();
  }, [student.public_id]);

  // Check if studentData is null or undefined
  if (!studentData) {
    return <div>Loading...</div>; // You can return a loading spinner or message while the data is being fetched.
  }


  return (
    <header>
      <div>
        {/* <HeaderBar /> */}
        {/* <TopMenu /> */}

          <Bio
            name={studentData.name}
            email1={studentData.email1}
            email2={studentData.email2}
            phone1={studentData.phone1}
            phone2={studentData.phone2}
            nationality={studentData.nationality}
            linkedin={studentData.linkedin}
            program={studentData.program}
            photo={studentData.photo}
            bio={studentData.bio}
            bio_title={studentData.bio_title}
          />
        <Row>
          <Col xs={11} sm={5} md={5} lg={5} xl={8} className="mt-4 mb-4 ps-4 mx-4">
          <h2 className="profile-title">Experience</h2>
              <Experience 
                professional_experience={studentData.professional_experience || "No experience provided"}
                work_timeline={studentData.work_timeline || "No work timeline provided"}
              />
          </Col>
          <Col xs={11} sm={5} md={5} lg={5} xl={3} className="mt-4 mb-4 mx-4">
            <h2 className="profile-title">Education</h2>
              <Education academic_timeline={studentData.academic_timeline || "No education provided"} />
              <h2 className="profile-title mt-5">Languages</h2>
              <Languages
                spoken_languages={studentData.spoken_languages || []}
              />
              <h2 className="profile-title mt-5">Additional Information</h2>
              <AdditionalInformation 
                additional_information={studentData.additional_information || "No additional information provided"}
            />
          </Col>
        </Row>
      </div>
      <Footer />
    </header>
  );
};

export default StudentProfileNew;
